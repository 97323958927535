.footer {
    padding-top: 145px;
    background-color: $secondary;
    padding-bottom: 50px;
}

ul.footer-contact-info {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
        margin: 0;
        padding: 0;
        margin-bottom: 15px;
        padding-left: 35px;
        position: relative;
        display: flex;
        align-items: center;
        min-height: 34px;

        .f-ico {
            position: absolute;
            left: 0;
            top: 3px;
            color: $primary;
            font-size: 18px;

            img,svg {
                width: 23px;
                height: 23px;
            }
        }

        address,
        .email-info-in,
        .phone-info-in {
            margin: 0;
            padding: 0;
            font-size: $px16;
            color: #fff;
            font-weight: 300;
            font-style: normal;
            line-height: 1.6;

            a {
                font-size: $px16;
                color: #fff;
                font-weight: 300;
                font-style: normal;
            }
        }

    }
}

.footer-box1 {
    figure {
        margin-bottom: 1rem;

        img {
            max-width: 100%;
            height: auto;
        }
    }
    @media(max-width:767px){
        max-width: 175px;
        margin: 0 auto;
    }
}

.moduletable_footernav {
    padding-left: 20px;
    @media(max-width:767px){ 
        padding-left: 0;
        text-align: center;
    }
    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: block;

        li {
            margin: 0;
            padding: 0;
            white-space: nowrap;
            display: block;

            a {
                display: block;
                font-size: $px16;
                font-weight: 400;
                color: #fff;
                padding: 10px 0;
            }

            ul {
                margin: 0;
                padding: 0;

                li {
                    margin: 0;
                    padding: 0;

                    a {
                        display: block;
                        font-weight: 300;
                        padding: 0;
                    }
                }
            }
        }
    }
}