.moduletable_fretrialtop {
  width: 100%;
  margin-top: -30px;
  padding-bottom: 40px;
  @media(max-width:767px){
    display: none;
  }
}

.moduletable_freetrial-left {
  max-width: 220px;
  @media(max-width:767px){
    max-width: 100%;
  }
  p {
    font-size: $px24;
    font-weight: normal;
    color: $primary;
    margin-bottom: 0.5rem;
    @media(max-width:767px){  
      img { transform: rotate(90deg);}
    }
  }

  h2 {
    font-size: 54px;
    font-weight: 400;
    color: $primary;
    line-height: 1.2;
    @media(max-width:767px){ 
      br { display: none;}
    }
  }
}


.trialform {
  margin-left: -80px;
  padding-bottom: 60px;
  @media(max-width:767px){  
    margin-left: 0;
    padding-bottom: 30px;
  }

  h2 {
    display: none;
  }

  .rsform-block-intro-text-free-trial {
    font-size: $px18;
    color: $secondary;
    font-weight: 300;
  }

  .formControlLabel {
    display: none;
  }

  .rsform-input-box,
  .rsform-select-box {
    border-radius: 0;
    background-color: $secondary;
    color: $primary;
    border-color: $secondary;
    font-size: $px16;
    font-weight: 300;

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: $primary;
      font-family: "Oswald", sans-serif;
      font-size: $px16;
      font-weight: 300;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      color: $primary;
      font-family: "Oswald", sans-serif;
      font-size: $px16;
      font-weight: 300;
    }

    &:-ms-input-placeholder {
      /* IE 10+ */
      color: $primary;
      font-family: "Oswald", sans-serif;
      font-size: $px16;
      font-weight: 300;
    }

    &:-moz-placeholder {
      /* Firefox 18- */
      color: $primary;
      font-family: "Oswald", sans-serif;
      font-size: $px16;
      font-weight: 300;
    }

  }

  .rsform-block-agree-terms-of-service {
    .formControlLabel {
      display: flex !important;
      position: relative;
      top: -5px;
      font-size: $px14;
      color: $secondary;
      font-weight: 300;
    }
  }

  .rsform-submit-button {
    display: inline-flex;
    height: 78px;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 100%;
    font-size: $px18;
    font-weight: 400;
    text-transform: uppercase;
    font-family: "Oswald", sans-serif;
  }

  .form-check-inline {
    color: $secondary;
    font-family: "Oswald", sans-serif;
    font-size: $px14;
    font-weight: 300;

    a {
      color: $secondary;
      text-decoration: underline;
    }
  }

  .custom-select {
    background-image: url(../images/dd-arrow.png);
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: auto;
  }

  .form-row {
    margin-right: -15px;
    margin-left: -15px;

    >.col,
    >[class*="col-"] {
      padding-right: 15px;
      padding-left: 15px;
    }

  @media(max-width:767px){
    .col-6 { flex: 0 0 100%; max-width: 100%;}
    .rsform-block-trial-captcha { display: flex; flex-wrap: wrap; justify-content: center;}

  }
  }

  .form-group {
    margin-bottom: 1.8rem;
  }
}

.moduletable_cloud-offer {
  font-weight: 300; color: $secondary; font-size: $px18;
}

.moduletable_freetrial-blog { 
img { max-width: 100%; height: auto; margin-bottom: 15px;}
p { font-size: $px18; color: $secondary; font-weight: 300;
strong { font-weight: normal; font-size: $px24;}
}
}