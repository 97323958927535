.section-testimonials-warp {
    padding: 125px 0;
    @media(max-width:990px){
        padding: 60px 0;   
    }
    @media(max-width:767px){
        padding: 30px 0;   
    }
}

.section-testimonials-header {
    text-align: center;
    padding-bottom: 25px;

    h4 {
        text-align: center;
        font-size: $px35;
        color: $secondary;
        font-weight: 300;
    }

    .header-testimonials-intro {
        text-align: center;
        font-weight: 300;
        font-size: $px30;
        color: $secondary;

        p {
            text-align: center;
            font-weight: 300;
            font-size: $px30;
            color: $secondary;
        }
    }
}

.section-home-testimonials {
    .moduletable {
        width: 100%;
    }
}

.mod-testimonials {
    margin-top: 30px;
    position: relative;
    @media(max-width:767px){ 
        padding-left: 20px;
        padding-right: 20px;
    }
    
    .slick-arrow:before { background: url(../images/arrow-shap-dark.png) no-repeat center center;
    @media(max-width:767px){
        background-size: 8px auto;
    }
    }
    .slick-arrow:after {     background: url(../images/arrow-shap-dark.png) no-repeat center center; transform: rotate(-180deg);
        @media(max-width:767px){
            background-size: 8px auto;
        }
    
    }
    .testimonials-main-swipe {
        margin-top: 60px;
        min-height: 330px;
        background-color: rgba($color: $primary-light, $alpha: 0.06);
        border: solid 1px $primary;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: auto;
        position: relative;
        padding: 65px 45px;
        margin-top: 58px;
        justify-content: space-between;
        align-items: center;
        @media(max-width:767px){
            padding: 65px 25px;  
            padding-bottom: 30px; 
        }

        .testimonials-intro-text {
            flex: 0 0 calc(100% - 145px);
            max-width: calc(100% - 145px);
            @media(max-width:767px){
                order: 2;
                flex: 0 0 100%;
                max-width: 100%;  

            }
            @media(max-width:374px){ 

                padding-top: 64px;
                padding-left: 0px;
                padding-right: 0px;
            }
        }

        .testimonials-intro-media {
            flex: 0 0 138px;
            max-width: 138px;
            @media(max-width:767px){
                order: 1;
                flex: 0 0 138px;
                max-width: 138px; 
                margin: 0 auto;
            }

            img {
                width: 138px;
                height: 138px;
                border-radius: 50%;
                object-fit: cover;
            }
        }
    }

    .testimonials-co-logo {
        width: 115px;
        height: 115px;
        border: solid 1px $primary;
        border-radius: 50%;
        background-color: #fff;
        display: block;
        position: absolute;
        left: 45px;
        top: -57px;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            max-width: 100%;
            height: auto;
        }
    }

    .testimonials-co-flag {
        display: block;
        width: 50px;
        height: 50px;
        position: absolute;
        left: 180px;
        top: -50px;

        img {
            max-width: 100%;
            height: auto;
        }
    }

    .testimonials-intro-text {
        font-size: $px18;
        color: $secondary;
        font-weight: 300;
        padding-left: 50px;
        padding-right: 30px;
        background: url(../images/quote-icon.png)no-repeat left top 22px;
        padding-top: 45px;
        padding-bottom: 45px;
        @media(max-width:767px){
            padding-bottom: 10px;
        }

        .user-info {
            text-align: right;
            font-size: $px18;
            padding-top: 10px;
            color: $secondary;
            font-weight: 500;
        }
    }
}

@media(max-width:574px)
{
.section-home-testimonials { padding-left: 15px; padding-right: 15px;}

}