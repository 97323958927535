.moduletable_com-history {
    width: 100%;
    padding-top: 75px;
    border-top: solid 1px $primary;

    @media(max-width:767px) {
        padding-top: 40px;
    }

    h3 {
        text-align: center;
        color: #2d3032;
        font-size: $px30;
    }
}

.section-history {
    padding-top: 65px;
    width: 100%;
    padding-bottom: 65px;

    @media(max-width:767px) {
        padding-top: 35px;
        padding-bottom: 35px;
    }
}

ul.history-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    width: 100%;

    li {
        display: flex;
        flex-wrap: wrap;
        margin: 0 0px;

        .history-col {
            padding: 0px;
            flex: 0 0 50%;
            max-width: 50%;
            position: relative;
            min-height: 190px;
            display: flex;
            align-items: center;
        }

        .history-box {
            padding: 30px;
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            @media(max-width:574px){
                padding: 15px;   
            }

            .history_year,
            h2.history_year {
                font-size: $px40;
                font-weight: 300;
                color: #2d3032;
                line-height: 1.3;
                @media(max-width:574px){ 
                    font-size: $px30;  
                }
            }
        }

        .small-intro {
            max-width: 320px;
            color: #2d3032;
            font-size: $px18;
            line-height: 1.5;
            @media(max-width:574px){   
                font-size: $px16;  
            }

        }

        .history-media {
            display: flex;
            height: 100%;
            align-items: center;
            width: 100%;
            @media(max-width:574px){  
                padding-right: 15px;
                img { max-width: 100%; height: auto;}
            }
        }

        .shap-ind {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 44px;
            height: 12px;

            .shap-line {
                display: block;
                position: absolute;
                height: 3px;
                width: 34px;
                top: 50%;
                transform: translateY(-50%);
            }

            .shap-round {
                display: block;
                position: absolute;
                height: 10px;
                width: 10px;
                border-radius: 50%;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        &.odd {
            .h-col-content {
                order: 1;
            }

            .h-col-media {
                order: 2;
            }

            .shap-ind {
                left: 100%;

                .shap-line {
                    left: 0;
                }

                .shap-round {
                    left: 34px;
                }
            }

            .history-media {
                padding-left: 52px;
                @media(max-width:574px){ 
                    padding-right: 15px;
                }
                justify-content: flex-start;
            }
        }

        &.even {
            .h-col-content {
                order: 2;
            }

            .h-col-media {
                order: 1;
            }

            .shap-ind {
                right: 100%;

                .shap-line {
                    right: 0;
                }

                .shap-round {
                    left: 0;
                }
            }

            .history-media {
                padding-right: 52px;
                justify-content: flex-end;
                @media(max-width:574px){ 
                    padding-left: 15px;
                }
            }
        }


    }
}