.cm-team-box-main {
    background-color: #f6f6f6;
    padding: 15px;
    text-align: center;
    height: 100%;
    padding-bottom: 66px;
    position: relative;

    .team-media {
        width: 168px;
        height: 168px;
        margin: 0 auto;
        overflow: hidden;
        border-radius: 50%;
        margin-bottom: 15px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .intro-info {
        font-size: $px17;
        color: #2d3032;
        font-weight: 300;

        h4 {
            font-size: $px20;
            font-weight: normal;
            margin: 0;
            padding: 0;
            line-height: 1.4;
            color: $secondary;
            text-transform: uppercase;
        }

        h5 {
            font-size: $px18;
            font-weight: normal;
            margin: 0;
            padding: 0;
            line-height: 1.4;
            color: $secondary;
            margin-bottom: 15px;
        }
    }

    p {
        font-size: $px15;
        color: #2d3032;
        font-weight: 300;
        margin-bottom: 10px;
    }

    .team-social-link {
        position: absolute;
        left: 0;
        bottom: 8px;
        height: 50px;
        padding: 10px 15px;
        text-align: center;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            color: $secondary;
            font-size: $px16;
            font-weight: 400;

            i {
                margin-right: 5px;
                color: $secondary;
            }

            span {
                margin-left: 5px;
                position: relative;
                display: inline-flex;
                top: -2px;
            }
        }

    }
}

.section-teamGrid {
    width: 100%; height: auto;
    padding-bottom: 30px;

    .row {
        .team-card-grid {
            padding: 15px;
        }
    }
}