$oswald:'Oswald',
sans-serif;

:root {
    font-size: 16px;
}

.overflow-hidden-active {
    overflow: hidden;
}

$px50:3.125rem;
$px48:3rem;
$px46:2.875rem;
$px45:2.8125rem;
$px44:2.75rem;
$px42:2.625rem;
$px40:2.5rem;
$px38:2.375rem;
$px36:2.25rem;
$px35:2.1875rem;
$px34:2.125rem;
$px32:2rem;
$px30:1.875rem;
$px28:1.75rem;
$px26:1.625rem;
$px24:1.5rem;
$px22:1.375rem;
$px21:1.313rem;
$px20:1.25rem;
$px18:1.125rem;
$px17:1.0625rem;
$px16:1rem;
$px15:0.9375rem;
$px14:0.875rem;
$px13:0.8125rem;
$px12:0.75rem;

.btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-height: 28px;
}

.section-mod-header {
    padding: 40px 0;

    .module-title {
        text-align: center;
        font-size: $px35;
        font-weight: 300;
        color: $primary;

        sup {
            font-size: 60%;
        }
    }
}

.module-title {
    font-weight: 300;
    font-size: $px35;
    color: $primary;
    text-align: center;
}

.moduletable_homebenefits {
    .module-title {
        padding-bottom: 35px;
    }
}

strong {
    font-weight: 700;
}

.section-customcardbox {
    .cm-card-box-main {
        text-align: center;
        height: 100%;
        position: relative;
        padding: 10px 0;

        @media(max-width:990px) {
            padding: 30px 0;
        }

        @media(max-width:767px) {
            max-width: 340px;
            margin: 0 auto;
        }

        &:after {
            content: "";
            width: 115px;
            height: 2px;
            background-color: $primary;
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
        }

        figure {
            text-align: center;
            margin-bottom: 20px;

            img {
                max-width: 100%;
                height: auto;
                max-width: 70px;
            }
        }

        .intro-info {
            text-align: center;

            h4 {
                font-size: $px20;
                color: $primary;
                font-weight: 400;
                margin-bottom: 10px;
                text-transform: uppercase;
            }

            p {
                font-size: $px15;
                color: #45484b;
                font-weight: 300;
            }

        }
    }
}

.form-click-warp {
    position: relative;

    .form-click-area {
        width: 196px;
        height: 196px;
        border-radius: 50%;
        background-color: #2d2f31;
        text-align: center;
        position: absolute;
        left: 50%;
        top: -98px;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateX(-50%);
        padding: 25px;
        z-index: 999;
        cursor: pointer;
    }
}

.toggle-main-title {
    color: $primary;
    font-size: $px21;
    font-weight: normal;
    text-align: center;
    display: block;
    line-height: 1.1;
    padding-bottom: 25px;
    background: url(../images/arrow-white.png)no-repeat center bottom;
}

.f-click {
    font-size: $px21;
    display: block;
    text-align: center;
    color: #fff;
    font-weight: 300;
    text-transform: uppercase;
    padding-top: 5px;
}

.moduletable_toggelform {
    padding-top: 130px;

    h3 {
        text-align: center;
        color: $secondary;
        font-weight: 300;
        font-size: $px24;
        text-transform: uppercase;
        margin-bottom: 15px;
    }

    .custom_toggelform {
        text-align: center;

        img {
            max-width: 100%;
            height: auto;
        }
    }
}

img {
    max-width: 100%;
}

.moduletable_pdfform {

    >h3 {
        text-align: center;
        color: $secondary;
        font-weight: 300;
        font-size: $px24;
        text-transform: uppercase;
        margin-bottom: 15px;
    }


}

.custom-pdfform-inner {
    width: 100%;
    height: auto;

    form {
        width: 100%;
        height: auto;
    }

    .toggle-form-box {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;

        .pdf-col-left {
            flex: 0 0 calc(100% - 360px);
            max-width: calc(100% - 360px);
            padding: 0 15px;

            .pdf-col-inner {
                border: solid 1px #D8E9F3;
                padding: 5px;

                ul.checkbox-list {
                    margin: 0;
                    padding: 0;
                    list-style-type: none;
                    display: flex;
                    flex-wrap: wrap;

                    li {
                        flex: 0 0 33.333%;
                        max-width: 33.333%;
                        padding: 8px;
                    }
                }
            }
        }

        .pdf-col-right {
            flex: 0 0 360px;
            max-width: 360px;
            padding: 0 15px;
        }
    }
}


.input-custom-checkbox {
    position: relative;
    width: 100%;

    input {
        position: absolute;
        opacity: 0;
        appearance: none;
        left: 0;
        top: 0;
    }

    label {
        width: 100%;
        height: 62px;
        display: flex;
        padding: 5px;
        position: relative;
        cursor: pointer;
        color: #064a76;
        border: solid 1px transparent;
        padding-left: 60px;
        padding-top: 33px;
        background: url(../images/pdf-icon.png) no-repeat left 5px top 5px;

        &:after {
            position: absolute;
            right: 4px;
            bottom: -6px;
            font-family: "Font Awesome 5 Pro";
            font-weight: 400;
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            line-height: 1;
            position: absolute;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            border: solid 1px #BCD0D1;
            font-size: 10px;
            display: flex;
            background-color: #eef6fb;
            align-items: center;
            justify-content: center;
        }

    }

    input:checked~label {
        border: solid 1px #BCD0D1;

        &:after {
            content: "\f00c";
        }
    }
}

.col-submit-btn {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 15px 15px;
    text-align: center;

    button {
        width: 126px;
        height: 32px;
        background: #054A74;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        text-transform: uppercase;
        border: 0;
    }
}

.pdf-col-inner {
    ul.contact-form {
        padding-left: 15px;
        list-style-type: none;
        margin: 0;

        li {
            padding-bottom: 4px;

            input[type="text"],
            input[type="email"],
            input[type="number"] {
                width: 100%;
                height: 32px;
                background-color: #99DAF6;
                color: #054A74;
                padding: 3px 15px;
                border: 0;
                border-radius: 0;

                &:focus {
                    outline: none;
                }

                &::-webkit-input-placeholder {
                    /* Edge */
                    color: #054A74;
                }

                &:-ms-input-placeholder {
                    /* Internet Explorer 10-11 */
                    color: #054A74;
                }

                &::placeholder {
                    color: #054A74;
                }

            }

            .input-chcek-style {
                margin: 3px 0;

                label {
                    margin: 0;
                    color: #054A74;
                    display: block;
                }

                .check-style2 {
                    position: relative;
                    margin-bottom: 5px;

                    input {
                        position: absolute;
                        opacity: 0;
                        left: 0;
                        top: 0;

                    }

                    label {
                        padding-left: 24px;
                        position: relative;
                        color: #054A74;
                        cursor: pointer;

                        &:before {
                            content: "";
                            width: 18px;
                            height: 18px;
                            border: solid 1px #054A74;
                            position: absolute;
                            left: 0;
                            top: 3px;
                        }

                        &:after {
                            left: 5px;
                            top: 6px;
                            font-size: 11px;
                            color: #054A74;
                            font-family: "Font Awesome 5 Pro";
                            font-weight: 700;
                            -moz-osx-font-smoothing: grayscale;
                            -webkit-font-smoothing: antialiased;
                            display: inline-block;
                            font-style: normal;
                            font-variant: normal;
                            text-rendering: auto;
                            line-height: 1;
                            position: absolute;
                        }
                    }

                    input:checked~label {
                        &:after {
                            content: "\f00c";
                        }
                    }
                }
            }
        }
    }
}

.alert-error {
    background-color: #fbd3d3;
}

.border-t {
    border-top: solid 1px $primary;
}

.border-b {
    border-bottom: solid 1px $primary;
}

.pad-y-60 {
    width: 100%;
}

.moduletable {
    width: 100%;
}

.module-title-secondary {
    color: $secondary;
    font-weight: 400;
}

.moduletable_pdfform {
    padding-top: 125px;
}

.module-toggle {
    width: 100%;
}

@media(max-width:990px) {
    .custom-pdfform-inner .toggle-form-box .pdf-col-left .pdf-col-inner ul.checkbox-list li {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .moduletable_pdfform {
        padding: 15px;
        padding-top: 125px;
    }
}


@media(max-width:767px) {
    .custom-pdfform-inner .toggle-form-box .pdf-col-left {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0 15px;
    }

    .custom-pdfform-inner .toggle-form-box .pdf-col-right {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0 15px;
    }

    .pdf-col-inner ul.contact-form {
        padding-left: 0;
        padding-top: 15px;
    }

    .google-captcha {
        display: flex;
        justify-content: center;

    }

}


@media(max-width:359px) {
    .google-captcha {
        .g-recaptcha {
            transform: scale(0.8);
        }
    }

    .input-custom-checkbox label {
        padding-top: 9px;
        padding-left: 50px;
    }
}

@media(min-width:768px) {

    .show-small,
    .togle-btn-form {
        display: none;
    }
}

@media(max-width:767px) {
    .hidden-small {
        display: none;
    }

    html,
    body {
        overflow-x: hidden;
    }
}