ul.meganav {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;

    li {
        padding: 0 10px;
        position: relative;

        a {
            display: block;
            padding: 10px 8px;
            color: #fff;
            font-size: 1rem;
            font-weight: 400;
@media(max-width:1199px){
    padding: 10px 5px; 
}
            &:hover {
                color: $primary-light;
            }
        }

        .sf-mega {
            position: absolute;
            z-index: 99;
            background-color: rgba($color: $secondary, $alpha: 0.85);
            transform: translateX(-10%);
            left: 10%;
            top: 100%;

            display: none;
        }

        &:hover,
        &:focus {
            >a {
                color: $primary-light;
            }
        }
    }
}
.meganav {

    .sf-mega {
        min-width: 130px;
    }
}



.meganav{
ul.mega-sub-dropdown {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;

    li {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 10px 5px;

        a {
            display: block;
            font-size: $px14;
            padding: 0px 5px;
            font-weight: 300;
            color: $primary-light;
        }

        &:hover {
            >a {
                color: #fff;
            }
        }
    }
}
}
.meganav {
.mega-dd+.sf-mega {
    width: 285px;
    
    ul.mega-sub-dropdown {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
    
        li {
            flex: 0 0 50%;
            max-width: 50%;
            padding: 10px 5px;
    
            a {
                display: block;
                font-size: $px14;
                padding: 0px 5px;
                font-weight: 300;
                color: $primary-light;
            }
    
            &:hover {
                >a {
                    color: #fff;
                }
            }
        }
    }
}
}
#mobile-nav { display: none; 
position: absolute;
left: 0; top: 100%;
background-color: $secondary;
min-width: 270px;

ul.slimmenu { margin: 0; padding: 0; list-style-type: none;
li { padding: 0; margin: 0; position: relative; 
a { display: block; display: block; position: relative; padding-right: 45px; padding-left: 15px; padding-top: 10px; padding-bottom: 10px; font-size: $px18;}
.sub-toggle { position: absolute; width: 35px; height: 35px; background: url(../images/spritesheet.png)no-repeat top -39px center; right: 0; top: 0;
&.expanded { background: url(../images/spritesheet.png)no-repeat top 13px center;}
}
ul.mega-sub-dropdown {margin: 0; padding: 0; list-style-type: none; 
li { 
    a { padding-left: 25px; font-size: $px16;  padding-top: 7px; padding-bottom: 7px; font-weight: 300;}
}
}
}
}

} 
@media(max-width:767px){
    .header-menu-warp { display: none;}
}