
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1054px
) !default;
$body-color:                #000000;
$font-family-base:         'Oswald', sans-serif;
$grid-gutter-width:         30px;
$primary:          #72c5ef;
$secondary:        #064a76;
$primary-light: #99dbf8;
$secondary-light:#0877bd;
$theme-dark:#2d3032;
$theme-light:#5a5b5c;
$border-color-white: #fff;
$border-color-primary: $primary;
$text-color-primary: $primary;

$font-size-base:              1rem ; // Assumes the browser default, typically `16px`
$headings-font-family:       'Oswald', sans-serif;
$headings-font-weight:        700 ;
$headings-line-height:        1.2 ;
$link-hover-decoration:       none ;
$btn-border-width:            1px ;
$btn-border-radius:           0px ;
$btn-padding-y:               0px ;
$btn-padding-x:               15px ;
$btn-font-weight:             400 ;

