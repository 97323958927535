.moduletable_homebottom {
    padding: 85px 0;
    @media(max-width:767px){ 
        padding-left: 15px;
        padding-right: 15px;
    }
    .module-title {
        padding-bottom: 30px;
    }

    .customhtml_homebottom {
        position: relative;
        @media(max-width:767px){
            display: flex;
            flex-wrap: wrap;
            width: 100%;
        }

        figure {
            margin: 0;
            padding: 0;
            @media(max-width:767px){ 
                order: 2;
                flex: 0 0 100%;
                max-width: 100%;
            }

            img {
                width: 100%;
                height: auto;
            }
        }

        .mod-customhtml-content {
            position: absolute;
            max-width: 305px;
            left: 0;
            top: 0;
            @media(max-width:767px){ 
                position: static;
                order: 1;
                flex: 0 0 100%;
                max-width: 100%;
            }

            p {
                font-size: $px20;
                font-weight: 300;
                color: $secondary;
            }

            .btn {
                color: #fff;
                font-size: 0.9375rem;
                font-weight: 400;
                text-transform: uppercase;

                span {
                    position: relative;
                    top: -2px;
                    margin-left: 5px;
                }

                &:hover {
                    background-color: #99dbf8;
                    color: #fff;
                    border-color: #99dbf8;
                }
            }
        }
    }
}

.text-normal {
    font-weight: 400 !important;
}

.page-header {
    margin: 0;
    padding: 0;

    h2 {
        font-size: $px30;
        color: #2d3032;
        margin: 0;
        padding: 0;
        font-weight: normal;
    }
}

.item-page {
    padding-bottom: 20px;
    margin-bottom: 30px;
    @media(max-width:767px){
        padding-bottom: 10px;
        margin-bottom: 15px;   
    }

    p {
        font-weight: 300;
        color: #2d3032;
        font-size: $px18;

        img {
            padding: 1rem 0;
            max-width: 100%;
            height: auto;
        }
    }

    h2 {
        font-size: $px30;
        color: #2d3032;
        margin: 0;
        padding: 0;
        margin-bottom: 5px;
        font-weight: normal;
    }

    hr {
        margin-top: 1rem;
        margin-bottom: 1rem;
        border: 0;
        border-top: 1px solid $primary;
    }
}

ul.content-list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
        font-weight: 300;
        color: #2d3032;
        font-size: $px18;

        .large-list-title {
            font-size: $px24;
            color: $primary;
        }

    }
}

.pad-y-30 {
    padding-bottom: 30px;
    padding-top: 30px;
}

.pad-y-60 {
    padding-bottom: 60px;
    padding-top: 60px;
    @media(max-width:767px){
        padding-bottom: 30px;
        padding-top: 30px;   
    }
}

.moduletable_benchmark {
    text-align: center;

    h3 {
        text-align: center;
        font-size: $px30;
        font-weight: 400;
        color: #2d3032;
        margin: 0;
        padding-bottom: 30px;
    }
}

.grid-list-3 {
    ul {
        margin: 0;
        padding: 30px 0;
        list-style-type: none;
        display: flex;
        margin: 0 -15px;
        @media(max-width:767px){ 
            flex-wrap: wrap;
        }
        li {
            flex: 0 0 33.3333%;
            max-width: 33.3333%;
            padding: 15px;
            text-align: center;
            @media(max-width:767px){
                flex: 0 0 100%;
                max-width: 100%;    
            }

         

            img {
                display: inline-block;
                max-width: 100%;
                height: auto;
            }
        }
    }

}

.moduletable_diagram {
    text-align: center;

    h3 {
        text-align: center;
        color: #2d3032;
        margin-bottom: 35px;
    }

    p {

        img {
            max-width: 100%;
            height: auto;
        }
    }
}

.moduletable_billing-offer {
    text-align: center;
    padding-bottom: 30px;
    @media(max-width:990px){
        padding-left:15px;   
    }

    h3 {
        font-weight: 400;
        font-size: $px30;
        margin-bottom: 10px;
    }

    p {
        font-size: $px30;
        line-height: 1.3;
    }
}

.moduletable_billingheading {
    padding-top: 20px;
    padding-bottom: 40px;

    .custom_billingheading {
        padding: 20px 0;
        text-align: center;
        font-size: $px40;
        line-height: 1;
        color: $primary;
        font-weight: 400;
        text-transform: uppercase;
        border-top: solid 2px $primary;
        border-bottom: solid 2px $primary;

        p {
            margin: 0;
            font-size: $px40;
            line-height: 1;
            color: $primary;
            line-height: 1;
            font-weight: 400;
            text-transform: uppercase;
        }
    }
}

.moduletable_bottombilling {
    margin-top: 30px;
    margin-bottom: 30px;

    h3 {
        padding: 20px 0;
        text-align: center;
        font-size: $px40;
        line-height: 1;
        color: $primary;
        font-weight: 400;
        text-transform: uppercase;
        border-top: solid 2px $primary;
        border-bottom: solid 2px $primary;
    }

    .custom_bottombilling {
        padding-top: 60px;
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        h2,
        h3,
        h4 {
            flex: 0 0 33.3333%;
            max-width: 33.3333%;
            padding-right: 15px;
            font-weight: 400;
            font-size: 1.875rem;
            color: #2d3032;
        }

        p {
            -webkit-box-flex: 0;
            flex: 0 0 66.66667%;
            max-width: 66.66667%;
            padding-right: 15px;
            padding-left: 15px;
            font-size: $px18;
            font-weight: 300;
        }
    }

}

.moduletable_price-table {
    padding-bottom: 30px;

    h3 {
        text-align: center;
        font-size: $px30;
        font-weight: 400;
        text-align: center;
        color: #2d3032;
    }

    p {
        text-align: center;
        font-size: 26px;
        font-weight: 300;
    }

    .table-main {
        width: 100%;
        height: auto;

        .main-td-left {
            width: 66.66667%;
            border-right: solid 1px #fff;

            .left-table {
                width: 100%;
            }
        }

        .main-td-right {
            width: 33.33333%;

            .side-table {
                width: 100%;
            }
        }
    }

    td {
        font-size: $px20;
        @media(max-width:767px){
            font-size: $px18; 
        }
        @media(max-width:574px){
            font-size: $px16;    
        } 
    }
}

.left-table {
    margin: 0;
    border: 0;

    tr {

        th,
        td {
            padding: 5px;
            text-align: center;
        }

        th {
            background-color: $primary;
            color: #fff;
            font-size: $px24;
            font-weight: 400;
            @media(max-width:767px){
                font-size: $px18; 
            }
            @media(max-width:574px){
                font-size: $px24;    
            } 
        }

        .left-tabl-th-col {
            border-top: solid 1px #fff;
        }

        .border-lft {
            border-right: solid 1px #fff;
        }

        td {
            background-color: #f6f6f6;
            border-bottom: solid 1px #fff;
            min-height: 35px;
            @media(max-width:574px){
                font-size: $px16;    
            } 
        }
    }
}

.side-table {
    tr {
        th {
            padding: 5px;
            height: 93px;
            text-align: center;
            background-color: $primary;
            color: #fff;
            font-size: $px24;
            font-weight: 400;
            @media(max-width:990px){
                font-size: $px20;    
            }
            @media(max-width:574px){
                font-size: $px14!important;    
            }  
        }

        td {
            padding: 5px;
            text-align: center;
            background-color: #f6f6f6;
            border-bottom: solid 1px #fff;
            min-height: 35px;
        }
    }
}

.moduletable_add-onservices {
    width: 100%;
    padding-bottom: 60px;

    table {
        width: 100%;
    }

    th,
    td {
        padding: 5px 10px;
        text-align: left;
    }

    th {
        background-color: $primary;
        color: #fff;
        font-size: $px24;
        font-weight: 400;
        @media(max-width:574px){
            font-size: $px16;    
        } 
    }

    td {
        padding: 5px 15px;
        background-color: #f6f6f6;
        border-bottom: solid 1px #fff;
        min-height: 35px;
        font-size: $px20;
        @media(max-width:574px){
            font-size: $px16;    
        } 
    }

    .td-lft {
        width: 66.66667%;
        border-right: solid 1px #fff;
    }

    td {
        width: 66.66667%;
    }

    td+td {
        width: 33.33333%;
        text-align: center;
    }

    ul {
        padding: 0;
        padding-top: 30px;
        margin: 0;
        list-style-type: none;
        text-align: left;
        @media(max-width:574px){
            padding: 0 15px; 
            padding-top: 35px;  
        } 

        li {
            font-size: $px20;
            font-weight: 300;
            @media(max-width:574px){ 
                font-size: $px16;   
            }
        }
    }

    p {
        text-align: center;
        position: relative;

        a {
            font-weight: 400;
            font-size: $px30;
            margin-top: 25px;
            color: $secondary;
            margin-top: 20px;
            display: inline-flex;
            align-items: center;

            i {
                font-style: normal;
                font-style: 80%;
                position: relative;
                top: -3px;
                margin-left: 5px;
                font-weight: 300;
            }

            &:hover {
                color: $primary;
            }
        }
    }

    .free-trail {
        margin-top: 40px;
        text-align: center;
        position: relative;

        hr {
            background-color: transparent;
            margin: 0;
            height: 1px;
            padding: 0;
            border-top: solid 1px $primary;
            width: 100%;
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
            z-index: -1;
        }
    }
}


.section-components {
    #user-registration {
        .control-group {
            margin: 15px 0;
        }
    }

}


@media(max-width:767px){
    .moduletable_freetrial-blog { flex: 0 0 100%; max-width: 100%; padding: 30px 15px;
    .custom_freetrial-blog  { max-width: 300px; margin: 0 auto;}
    }
}