.section-home-brands-logos {
    padding: 50px 0;

    .section-repeateable-images-warp {
        .section-repeateable-images-header {
            .module-title {
                margin-bottom: 40px;
            }

            .header-repeateable-images-intro {
                font-size: 18px;
                font-weight: 300;
                text-align: center;
            }
        }

        .mod-repeateable-images {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 50px;
            align-items: center;
            margin-top: 40px;

            img {
                &:hover {
                    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
                    /* Firefox 3.5+, IE10 */
                    filter: gray;
                    /* IE6-9 */
                    -webkit-filter: grayscale(100%);
                    /* Chrome 19+ & Safari 6+ */
                    -webkit-transition: all .6s ease;
                    /* Fade to color for Chrome and Safari */
                    -webkit-backface-visibility: hidden;
                    /* Fix for transition flickering */
                }
            }
        }
    }
}