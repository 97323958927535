.section-offer { 
.offer-media { margin: 0; padding: 0;
    @media(max-width:767px){
        padding-top: 10px; padding-bottom: 10px;
    }
img { width: 100%; height: auto;}
}
.offer-inner-title {  color: #2d3032; font-size: $px30; font-weight: 400;}
}
ul.offer-list {
    margin: 0; padding: 0; list-style-type: none; padding-top: 15px;

    li { 
        position: relative;
        padding-left: 35px;
        margin-bottom: 15px;
        i { position: absolute; left: 0; top: 4px; font-size: $px22;}
        .offer-list-box-item { font-size: $px20; color: #2d3032; font-weight: 300; line-height: 1.3;
            h4 {  font-weight: 300; font-size: $px28; margin: 0; }
        }
    }

}