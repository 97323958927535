.moduletable_feature {
    h3 { text-align: center; padding: 0 15px; font-size: $px30; color:  #2d3032; font-weight: 400; padding-bottom: 30px; margin: 0;}
}
.section-feature { padding-top: 30px;}
ul.feature-list {
    margin: 0; padding: 0; list-style-type: none;
    @media(max-width:574px){
        padding: 0 15px; 
    }
    li { display: flex; flex-wrap: wrap; margin-bottom: 30px; min-height: 70px;
        .feature-box-main {  flex: 0 0 100%; max-width: 100%; padding-left: 100px; position: relative; min-height: 70px;  display: flex; align-items: center;
        .feature-icon { position: absolute; left: 0; top: 0; width: 70px; height: 70px; margin: 0; padding: 0;
        img { width: 70px; height: auto;}
        }
        h4 { font-size: $px28; font-weight: 400; margin: 0; padding: 0; margin-bottom: 5px;}
        .intro-info {  font-size: $px20; color: #2d3032; font-weight: 300;}
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}