.contactform {
    h2 {
        font-weight: 300;
        font-size: $px30;
        color: $secondary;
    }

    .formControlLabel {
        display: none;
    }

    .rsform-block-intro-text {
        font-size: $px18;
        color: $secondary;
        font-weight: 300;
    }

    .form-control {
        //  background-color: $secondary;
        height: 34px;
        padding: 0 10px;
        font-size: $px16;
        color: $secondary; //$primary;
        font-weight: 300;
        border-radius: 0;

        &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: $secondary; //$primary;
        }

        &::-moz-placeholder {
            /* Firefox 19+ */
            color: $secondary; //$primary;
        }

        &:-ms-input-placeholder {
            /* IE 10+ */
            color: $secondary; //$primary;
        }

        &:-moz-placeholder {
            /* Firefox 18- */
            color: $secondary; //$primary;
        }
    }

    textarea.form-control {
        height: 76px;
        padding-top: 5px;
    }

    .rsform-block-submit,
    .rsform-block-subscribe {
        display: flex;
        align-items: flex-end;
        height: 100%;
        width: 100%;
        justify-content: flex-end;
        width: 100%;
        margin-bottom: 0;
        height: auto;

        .formControls {
            padding-bottom: 1.1rem;

            button.btn-primary {
                background-color: $secondary;
                width: 125px;
                height: 32px;
                border-color: $secondary;
                color: #fff;
                font-weight: 300;
                text-transform: uppercase;
                font-size: $px16;

                &:hover,
                &:active,
                &:focus {
                    background-color: $primary-light;
                    border-color: $primary-light;

                }
            }

        }
    }
}

@media(max-width: 767px) {
    .contactform {
        .form-row {
            .col-6 {
                flex: 0 0 100%;
                max-width: 100%;
            }

            .rsform-block-submit {
                justify-content: center;
            }

            .rsform-block-rcaptcha2 {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
            }
        }
    }
}