.landing-page {
    background-color: #064A76;

    .landing-page-wrapper {
        .container-fluid {
            >.row {
                .flex-grow-1 {
                    @include respond(992) {
                        max-width: calc(100% - 60px);
                    }
                }

                >.data {
                    padding: 150px 80px 100px 200px;
                    color: #fff;

                    @include respond(1900) {
                        padding: 90px 80px 100px 100px;
                    }

                    @include respond(1400) {
                        padding: 60px;
                    }

                    @include respond(468) {
                        padding: 20px;
                    }

                    h1,
                    h2 {
                        font-size: 46px;
                        font-weight: 300;
                        line-height: 68px;
                        color: #fff;
                        border-bottom: 2px solid rgba(255, 255, 255, 0.6);
                        width: fit-content;
                        max-width: 540px;
                        margin-bottom: 50px;
                        padding-bottom: 7px;

                        @include respond(1200) {
                            font-size: 32px;
                            line-height: 54px;
                        }
                    }

                    p {
                        font-size: 24px;
                        font-weight: 300;
                        color: #fff;
                        line-height: 36px;
                        margin-bottom: 40px;
                        max-width: 700px;

                        @include respond(1900) {
                            max-width: 490px;
                        }

                        @include respond(1400) {
                            max-width: 350px;
                        }

                        @include respond(1200) {
                            max-width: 290px;
                        }

                        @include respond(992) {
                            max-width: 100%;
                        }
                    }

                    .features {
                        margin-top: 100px;
                        margin-bottom: 200px;

                        @include respond(1400) {
                            margin-bottom: 130px;
                        }

                        >.row {
                            margin-bottom: 50px;
                        }

                        h3 {
                            font-size: 46px;
                            font-weight: 300;
                            color: #fff;
                            margin-bottom: 22px;

                            @include respond(1900) {
                                font-size: 38px;
                            }

                            @include respond(1200) {
                                font-size: 32px;
                            }

                            @include respond(468) {
                                font-size: 28px;
                            }
                        }

                        .icon {
                            padding-top: 10px;
                        }

                        p {
                            margin-bottom: 0;
                        }
                    }

                    .bottom-row {
                        >.row {
                            align-items: center;

                            @include respond(768) {
                                text-align: center;
                                justify-content: center;
                            }
                        }

                        a {
                            font-size: 46px;
                            font-weight: 400;
                            color: #fff;

                            @include respond(1900) {
                                font-size: 32px;
                            }

                            @include respond(1600) {
                                font-size: 30px;
                            }

                            @include respond(1400) {
                                font-size: 27px;
                            }

                            @include respond(768) {
                                margin-bottom: 20px;
                                display: block;
                            }
                        }
                    }
                }

                >.right-side {
                    clip-path: polygon(10% 0, 100% 0, 100% 100%, 0% 100%);
                    background-color: #fff;
                    width: 790px;
                    max-width: 100%;
                    height: 100vh;
                    position: sticky;
                    top: 0;
                    right: 0;
                    padding: 0;

                    @include respond(1600) {
                        width: 650px;
                    }

                    @include respond(1200) {
                        width: 500px;
                    }

                    @include respond(992) {
                        clip-path: unset;
                        width: 100%;
                        height: auto;
                    }

                    .inner-container {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 100%;
                        padding-right: 22px;
                        padding-left: 100px;

                        @include respond(992) {
                            padding: 15px;
                            position: relative;
                            top: auto;
                            left: auto;
                            transform: unset;
                        }

                        .logo {
                            text-align: center;
                            margin-bottom: 50px;
                        }

                        .trusted-by {
                            margin-bottom: 50px;

                            h3 {
                                font-size: 24px;
                                font-weight: 300;
                                color: #064A76;
                                margin-bottom: 18px;
                                text-align: center;
                            }

                            >.row {
                                display: grid;
                                grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                                row-gap: 60px;
                                grid-template-areas:
                                    ". . . . ."
                                    ". . . . ."
                                    ". . . . .";
                                justify-content: center;
                                width: 100%;
                                margin: 0 auto;

                                @include respond(1400) {
                                    row-gap: 30px;
                                }
                            }
                        }

                        .rsform {
                            background-color: #F8F8F8;
                            border: 2px solid #064A76;
                            padding: 15px;
                            max-width: 590px;
                            margin: 0 auto;
                            box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
                            border-radius: 50px;

                            form {
                                margin: 0 auto;
                                max-width: 355px;
                                text-align: center;

                                h2 {
                                    font-size: 24px;
                                    font-weight: 300;
                                    color: #064A76;
                                    margin-bottom: 14px;
                                }

                                .form-group {
                                    margin-bottom: 12px;

                                    .formControls {
                                        input {
                                            text-align: center;
                                            border: 2px solid #656565;
                                            border-radius: 50px;
                                            height: 40px;
                                            font-size: 20px;
                                            font-weight: 300;
                                            color: #575757;

                                            @include placeholder {
                                                color: #575757;
                                            }
                                        }

                                        .rsform-submit-button {
                                            color: #fff;
                                            background-color: #064A76;
                                            border: 0px;
                                            font-size: 20px;
                                            font-weight: 300;
                                            border-radius: 100px;
                                            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.11);
                                            text-transform: uppercase;
                                            padding: 5px 17px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}