.layout-blog {
    .blog {
        img {
            max-width: 100%;
            height: auto;
        }
    }

    .blog-wrapper,
    .item-wrapper {
        .suggested-posts {
            position: sticky;
            top: 0;

            @include respond(768) {
                margin-top: 4rem;
            }

            h3 {
                text-align: initial;
                margin-bottom: 15px;

                @include respond(768) {
                    text-align: center;
                }
            }

            ul {
                margin: 0;
                padding: 0;
                list-style: none;

                li {
                    border-top: 1px solid #e9e9e9;

                    &:last-child {
                        border-bottom: 1px solid #e9e9e9;
                    }

                    a {
                        display: block;
                        padding: 7px 0;
                        font-weight: 300;
                        letter-spacing: 0.6px;
                    }
                }
            }
        }

        .related-posts {
            margin-top: 4rem;

            h2 {
                margin-bottom: 3rem;
            }

            ul {
                margin: 0;
                padding: 0;
                list-style: none;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: 1fr;
                gap: 0px 10px;
                grid-template-areas:
                    ". . .";

                @include respond(768) {
                    grid-template-areas:
                        "."
                        "."
                        ".";
                    grid-template-columns: repeat(1, 1fr);
                    gap: 30px 10px;
                }

                li {
                    text-align: center;

                    figure {
                        height: 220px;
                        overflow: hidden;
                        position: relative;

                        img {
                            object-fit: cover;
                            position: absolute;
                            height: 100%;
                            width: 100%;
                            left: 0;
                            @include transition(all .2s ease-out);
                        }
                    }

                    .mod-articles-category-title {
                        display: block;
                        margin-bottom: 0.5rem;
                    }

                    &:hover {
                        figure {
                            img {
                                transform: scale(1.1);
                            }
                        }
                    }
                }
            }
        }
    }

    div.pagination {
        @media screen and (max-width: 980px) {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    ul.pagination {
        margin: 0 20px;

        @media screen and (max-width: 980px) {
            display: flex;
            flex-wrap: wrap;
        }
    }
}