.section-tabnav-slick {
    margin-bottom: 40px;
    overflow: hidden;

    @media(min-width:200px) {
        .slick-list {
            margin-left: -15px;
            padding-bottom: 20px;
        }

        .slick-track {
            transform: translate3d(0px, 0px, 0px) !important;
        }
    }

    .slick-track {
        transform: translate3d(0px, 0px, 0px);
        .slick-tabnav-item:first-child {
            .spcover {
                position: absolute;
                width: 25px;
                height: 100%;
                background-color: #000;
                left: 5px;
                top: 0;
                @media(max-width:990px){ 
                    left: 0px;  
                    width: 21px; 
                }
            }
        }

        .slick-tabnav-item+.slick-tabnav-item {
            .spcover {
                display: none;
            }
        }
    }
}

.slick-tabnav-item {
    display: inline-flex;
    position: relative;
    .slick-tab-nav {
        transform: skewX(-45deg);
        position: relative;
        &:after {
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            height: 120%;
            width: 3px;
            background-color: #fff;
        }
    }

    .slick-tab-nav-title {
        display: inline-flex;
        padding: 5px 30px;
        font-size: $px22;
        position: relative;
        font-weight: 300;
        color: #fff;
        cursor: pointer;
        transform: skewX(45deg);
        @media(max-width:990px){
            padding: 5px 24px; 
            padding-right: 28px;   
        }

        .active-angle {
            position: absolute;
            left: 0%;
            opacity: 0;
            transition: all 1s;
            top: 100%;
            width: 26px;
            height: 16px;
            transform: translateX(-50%);
            clip-path: polygon(100% 0, 0 0, 50% 100%);
        }

    }

    &.slick-current {
        .slick-tab-nav-title {
            .active-angle {
                left: 50%;
                opacity: 1;
            }
        }
    }
}

.tab-navcontent-main {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;

    .tab-navcontent-intro {
        flex: 0 0 430px;
        max-width: 430px;
        padding-right: 15px;

        .tab-content-title {
            font-size: $px35;
            font-weight: 300;
            color: $secondary;
            line-height: 1.3;
        }

        .tab-content-text {
            color: $secondary;
            font-size: $px20;
            padding-top: 20px;
            padding-bottom: 20px;
        }

        .tab-readmore-btn {
            .btn {
                color: #fff;
                font-size: $px15;
                font-weight: 400;
                text-transform: uppercase;

                span {
                    margin-left: 5px;
                    position: relative;
                    top: -2px;
                }

                &:hover,
                &:focus {
                    background-color: $primary-light;
                    color: #fff;
                    border-color: $primary-light;
                }
            }
        }

        @media(max-width:767px){
            order: 2;
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    .tab-navcontent-media {
        flex: 0 0 calc(100% - 430px);
        max-width: calc(100% - 430px);
        padding-left: 15px;

        img {
            max-width: 100%;
            height: auto;
        }

        @media(max-width:767px){
            order: 1;
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}

.slick-arrow {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: transparent;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-indent: -9999px;
    font-size: 0;
    line-height: 0;
    border: 0;
    @media(max-width:767px){
        width: 30px;
        height: 30px;   
    }

    &:focus {
        outline: none;
    }

    &:before {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        font-family: "Font Awesome 5 Pro";
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        line-height: 1;
        font-weight: 400;
        font-size: 2.5rem;
        text-indent: 0;
        color: $primary;
        content: "";
        background: url(../images/arrow-shap.png)no-repeat center center;
        @media(max-width:767px){ 
            background-size: 8px auto;
        }
    }

    &.slick-prev {
        left: -75px;
        @media(max-width:767px){  
            left: -30px;
        }
        @media(max-width:574px){  
            left: -15px;
        }

    }

    &.slick-next {
        right: -75px;
        @media(max-width:767px){  
            right: -30px;
        }
        @media(max-width:574px){  
            right: -15px;
        }

        &:before {
            transform: rotate(-180deg);
        }
    }

    &:hover {
        background-color: rgba($color: $primary-light, $alpha: 0.3);
    }

    &.slick-disabled {
        pointer-events: none;

        &:before {
            opacity: 0.3;
        }
    }
}

.tab-nav-slick { display: none;}

@media(max-width:767px){
    .tab-nav-slick { display: block; width: 100%; height: auto;
    ul.section-tabnav-slick { margin-left: 0; margin-right: 0; margin-top: 0; list-style-type: none; width: 100%;
    li { display: inline-flex; margin-bottom: 10px; padding-bottom: 10px;} 
    }
   
    }

    .section-tabnav-slick {
        .slick-list {
            margin-left: 0!important;
            padding-left: 20px;
            padding-bottom: 20px;
            .slick-track { width:100%!important;}
        }
        .slick-tabnav-item { margin-bottom: 25px;
            &:first-child {
                .spcover  { display: none;}
            }
        }
      
    }
    .tab-navcontent-main { padding: 0 20px;}  
    .tab-navcontent-main .tab-navcontent-media { padding-left: 0;}
    .section-tabnav-slick { margin-bottom: 10px;}
    .tab-navcontent-main .tab-navcontent-intro { padding-right: 0; padding-top: 15px;}
}
@media(max-width:574px){
.section-hometab { padding-left: 10px; padding-right: 10px;}

  }
@media(max-width:411px){ 
    .slick-tabnav-item .slick-tab-nav-title {
        padding: 5px 24px;
        padding-right: 34px;
        font-size: 18px;
    }
}
@media(max-width:375px){ 
    .slick-tabnav-item .slick-tab-nav-title {
        padding: 5px 24px;
        padding-right: 26px;
        font-size: 17px;
    }
}