.section-acd   { 
margin: 0; 
padding: 0;



}
ul.acd-list-warp { margin: 0; 
    padding: 0;  list-style-type: none; 
li {  margin: 0; padding: 0; padding-top: 30px; 
.acd-header {
    a { display: block; padding: 0; color: $primary; font-size: $px28; line-height: 1.3; font-weight: 300; padding-bottom: 3px; border-bottom: solid 1px $primary; position: relative;
    padding-left: 25px;
    i { position: absolute; left: 0; top: 50%; transform: translateY(-50%) rotate(90deg);  transition: all 0.4s linear;}
    &.collapsed {
        i {transform: translateY(-50%) rotate(0deg);   }
    }
    }

}
.panel-body { font-size: $px18; color: #2d3032; font-weight: 300; padding: 0; padding-top: 25px; padding-bottom: 25px; 
p {font-size: $px18; color: #2d3032; font-weight: 300; margin: 0; }
p + p { margin-top: 15px;}
}
}
}
