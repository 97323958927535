.section-newsletter {
    padding-top: 135px;
    background: url(../images/newsletter-bg.jpg)no-repeat center top;
    background-size: cover;
    position: relative;

    @media(max-width:990px) {
        padding-top: 100px;
    }

    @media(max-width:767px) {
        padding-top: 80px;
    }

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 102px;
        background: url(../images/bottom-shap2.1.png) no-repeat center top;
        z-index: 10;

        @media(max-width:1199px) {
            background: url(../images/bottom-shap2_1024.png) no-repeat center top;
            height: 94px;
        }

        @media(max-width:767px) {

            bottom: auto;

        }

        @media(max-width:499px) {
            background: url(../images/bottom-shap2_mobile.png)no-repeat center top;
            height: 33px;


        }


    }

    padding-bottom: 96px;
}

.moduletable_newsletter {
    padding-bottom: 70px;
    display: block;
    width: 100%;

    h3,
    h2 {
        text-align: center;
        font-size: $px30;
        font-weight: 400;
        color: $secondary;
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    .acym_introtext,
    .rsform-block-intro p {
        text-align: center;
        font-size: $px20;
        font-weight: 400;
        color: $secondary;
        margin-bottom: 15px;
    }

    .rsform-block-subscribe {
        justify-content: center !important;
    }

    .acym_module_form,
    .rsform_newsletter {
        max-width: 696px;
        margin: 0 auto;

        input[type="text"],
        input[type="email"] {
            height: 30px;
            padding: 2px 10px;
            border: 0;
            background-color: #fff;
            width: 100%;
            font-size: 1rem;
            color: $secondary;
            font-weight: 300;

            &:focus {
                outline: none;
            }

            &::-webkit-input-placeholder {
                /* Edge */
                color: $secondary;
            }

            &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: $secondary;
            }

            &::placeholder {
                color: $secondary;
            }
        }

        .acysubbuttons {
            padding-top: 10px;
        }

        .btn {
            font-size: $px15;
            font-weight: 400;
            height: 30px;
            min-width: 125px;
        }
    }
}