.theme-header {
    height: 114px;
    background-color: $secondary;
    position: relative;
    z-index: 150;

    @media(max-width:990px){  
        height: auto;

    }   
}

.brand-logo {
    position: relative;
    display: flex;
    height: 114px;
    flex: 0 0 300px;
    max-width: 300px;
    @media(max-width:990px){
        flex: 0 0 100%;
    max-width: 100%;   
    }

    &:before {
        content: "";
        position: absolute;
        background-color: #fff;
        width: 100%;
        height: 100%;
        transform: skewX(-45deg);
        z-index: 3;
        @media(max-width:990px){ 
            width: 300px;    
        }
    }

    figure {
        margin: 0;
        padding: 0 30px;
        position: relative;
        z-index: 5;

        a {
            display: block;
            padding-top: 50px;
        }
    }
}

.header-right-panel {
    flex: 0 0 calc(100% - 300px);
    max-width: calc(100% - 300px);
    display: flex;
    justify-content: space-between;
    @media(max-width:990px){  
        flex: 0 0 100%;
        max-width: 100%;   
        justify-content: center;
    }
    @media(max-width:767px){  
        flex: 0 0 100%;
        max-width: 100%;   
        justify-content: space-between;
        height: 100px; 
        align-items: center;
        background: #fefbfc;
        position: relative;
        padding: 0 15px;
        &:before{ content: ""; position: absolute; left: -50%; width: 50%;   background: #fefbfc; height: 100%; }
        &:after{ content: ""; position: absolute; right: -50%; width: 50%;   background: #fefbfc; height: 100%; }

    }
    @media (max-width: 575px) {
        &:before{ content: ""; position: absolute; left: -15px; width: 15px;   background: #fefbfc; height: 100%; }
        &:after{ content: ""; position: absolute; right: -15px; width: 15px;   background: #fefbfc; height: 100%; }
    }
}

.header-menu-warp {
    padding-top: 70px;
    @media(max-width:990px){  
        padding-top: 0;
      
     }
}

.user-login-header {
    @media(min-width:768px) and (max-width:990px){  
        position: absolute; 
        width: 238px;
        min-width: 115px; right: 15px; top: 0;
        z-index: 20;
    } 
    @media(max-width:767px){
        position: relative; z-index: 5;
    } 
}

@media(max-width:767px){ 
    .menu-toggle { background-color: transparent; border: 0; cursor: pointer;
    height: 45px; display: inline-flex; align-items: center;
    i { font-size: 2rem; color: $secondary;}
    span { color: $secondary; font-size: $px24; margin-left: 10px;}
    }
}
@media(max-width:374px){
    .menu-toggle {
     
        span { font-size: $px20; margin-left: 5px;}
        }

  }