.section-home-benefits { background-color: #f6f6f6; padding: 165px 0;
@media(max-width:990px){
    padding: 60px 0;   
}

}

.moduletable_benefits {

    h3 { text-align: center; font-weight: 400; font-size: $px30; color:#2d3032; padding-bottom: 35px; text-transform: uppercase;
        @media(max-width:767px){padding-bottom: 15px; }
    }
}
.benefits-grid-col { 
padding-top: 15px;
padding-bottom: 15px;
@media(max-width:574px){
    padding-left: 30px;
    padding-right: 30px;
 }
.benefits-list { background-color: #f6f6f6; padding: 25px; height: 100%; position: relative; padding-bottom: 85px;
.benefits-list-item { text-align: center;
    h4 {  text-align: center; font-weight: 300; font-size: $px30; margin-bottom: 10px; padding: 0;}
    font-size: $px20;
    color: #2d3032; font-weight: 300;
}
.benefits-icon-warp { padding-top: 20px; position: absolute; left: 0; bottom: 25px; width: 100%; text-align: center;
i { font-size: 2.5rem;}
}
}

}