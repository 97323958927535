.cm-register-login-wrapper-root {
  background: url(../images/login-cloud.png)no-repeat 0 0;
  max-width: 238px;
  min-height: 100px;
  display: flex;
  flex-wrap: wrap;

  @media(max-width:767px) {
    background: transparent;
    position: relative;
    max-width: 260px;
  }

  &.cm-logout-root {
    width: 238px;
    text-align: center;
    justify-content: center;

    .form-vertical {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    .login-greeting {
      font-size: $px16;
      color: $secondary;
      padding-top: 20px;
      text-align: center;
      padding-bottom: 0px;
    }

    .logout-btn-warp {
      display: flex;
      flex-wrap: wrap;
      flex: 0 0 100%;
      max-width: 100%;
      justify-content: center;

      .btn {
        min-width: 88px;
        font-size: $px15;
        color: #fff;
        border-radius: 0;
        text-transform: uppercase;

        &:hover,
        &:focus {
          color: $secondary;
        }
      }

      .logout-readmore-link {
        a {
          padding-left: 15px;
          font-size: $px14;
          position: relative;
          color: $secondary;
          text-decoration: underline;

          &:hover,
          &.active {
            color: $primary;
          }

          i {
            position: absolute;
            color: $primary;
            right: -30px;
            top: -2px;
            font-size: 24px;

            &:before {
              color: $secondary;
              opacity: 1;
            }

            &:after {
              color: $primary;
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .error {
    color: red;
    font-weight: 300;
    font-size: $px14;
  }
}

ul.cm-register-login-tab {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  li {
    padding: 0;
    padding-right: 5px;

    .popup-btn {
      display: inline-flex;
      padding: 4px 10px;
      background-color: $primary;
      color: $secondary;
      font-size: $px12;
      cursor: pointer;
      text-transform: uppercase;

      &:hover,
      &.active {
        color: #fff;
      }
    }

    .btn-link-register a:hover {
      color: white;
    }

    a {
      padding-left: 15px;
      font-size: $px14;
      position: relative;
      color: $secondary;
      text-decoration: underline;

      &:hover,
      &.active {
        color: $primary;
      }

      i {
        position: absolute;
        color: $primary;
        right: -30px;
        top: -2px;
        font-size: 24px;

        &:before {
          color: $secondary;
          opacity: 1;
        }

        &:after {
          color: $primary;
          opacity: 1;
        }
      }
    }

  }
}

.cm-register-login-wrapper {
  width: 100%;
}

.cm-register-login-box {
  position: relative;

}

.cm-popup-container {
  position: absolute;
  transform: scale(0);
  background: #fff;
  width: 238px;
  padding: 15px;
  padding-top: 40px;
  padding-bottom: 0;
  transition: all 0.5s;
  top: 22px;
  left: 0;
  opacity: 0;
  z-index: 15;
  @media(max-width:767px){
    left: 50%;
    top: 15px;
    transform: scale(0) translateX(-50%); 
  }
  @media(max-width:400px){ 
    left: 45%;
  }
  @media(max-width:375px){ 
    left: 40%;
  }
  &.is-active-popup {
    transform: scale(1);
    opacity: 1;
    @media(max-width:767px){ 
      transform: scale(1) translateX(-50%);  
    }
  }

  .cm-inputbox-control {
    padding-bottom: 10px;
  }

  input[type="text"],
  input[type="email"],
  input[type="password"] {
    height: 32px;
    border: solid 1px $primary;
    padding: 0 10px;
    margin: 0;
    font-size: $px12;
    color: $secondary;
    width: 100%;

    &:focus {
      border-color: $secondary;
      outline: none;
    }

    &::-webkit-input-placeholder {
      /* Edge */
      color: $secondary
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $secondary;
    }

    &::placeholder {
      color: $secondary;
    }
  }
}

.cm-title-custom {
  flex: 0 0 100%;
  text-align: center;
  font-size: $px24;
  font-weight: 300;
  color: $secondary;
  padding-top: 28px;
  margin: 0;
  @media(max-width:400px){
    font-size: $px18;
  }
}

.showpass {
  display: none;
}

.popup-close {
  position: absolute;
  font-size: 18px;
  color: $primary;
  right: 5px;
  top: 4px;
  z-index: 15;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cm-button-control {
  text-align: center;
  padding-top: 10px;

  button.btn {
    min-width: 88px;
    font-size: $px15;
    color: #fff;
    border-radius: 0;
    text-transform: uppercase;

    &:hover,
    &:focus {
      color: $secondary;
    }
  }

  &.regester-btn-warp {
    padding-top: 0;
    padding-bottom: 15px;
  }
}

.cm-list-group {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  padding: 10px 0;
  padding-bottom: 20px;

  a {
    font-weight: 300;
    font-size: $px14;
    color: $secondary;
  }
}

.cm-list-wrapper-bottom {
  background-color: $secondary;
  padding: 15px;
  text-align: center;
  margin: 0 -15px;

  h5 {
    margin: 0;
    padding: 10px 0;
    font-size: $px16;
    color: $primary;
    font-weight: 400;
  }
}

.cm-free-trial-btn {
  .btn {
    text-transform: uppercase;
    color: #fff;
    font-size: $px15;

    &:hover,
    &:focus {
      color: $secondary;
    }
  }
}

.login-title-form-row {
  width: 100%;

  @media(max-width:767px) {
    display: flex;
    align-items: center;
    min-height: 70px;
    flex-wrap: wrap;
    padding-right: 95px;
  }
  @media(max-width:400px) { 

    padding-right: 65px;
  }
}
.cm-register-login-warp-ul { width: 100%;}
@media(max-width:767px) {
  .togle-btn-form {
    display: inline-flex;
    position: absolute;
    right: 0; top: 50%;
    transform: translateY(-50%);
  }

  .cm-title-custom {
    padding-top: 18px;
  }

  .cm-register-login-wrapper {
    .cm-register-login-warp-ul {
      display: none;
      position: absolute;
      top: 100%;
      right: 0;
      z-index: 10;
      background-color: $primary;
      min-width: 200px;
    }
    ul.cm-register-login-tab {
    
      background-color: $primary;
      display: block;

      li {
        padding: 0;

        a {
          display: flex;
          padding: 8px 15px;
          font-size: $px14;

          i {
            display: none;
          }

        }
        .popup-btn {display: flex; font-size: $px14; padding: 8px 15px; }
      }
    }
  }

  



  .togle-btn-form {
    .btn-form-toggle {
      background: url(../images/mobile-cloud-btn.png)no-repeat 0 0;
      padding: 0;
      margin: 0;
      border: 0;
      width: 76px;
      height: 45px;
    }
  }
}
@media(max-width:400px){
  .togle-btn-form .btn-form-toggle {
    width: 64px;
    height: 45px;
    background-size: 60px auto;
    background-position: right center;
  }
}