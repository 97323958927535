.video-box-main {
position: relative;
@media(max-width:767px){
    padding-bottom: 30px;
    margin-top: 15px;
}
.video-title-header { padding-bottom: 30px;
    h4 { font-size: $px30; color: $primary; font-weight: 400; text-align: center;}
}
.responsive-frame { line-height: 0; padding-bottom: 56.25%; position: relative; z-index: 5;
    figure { background-color: $secondary; margin: 0; padding: 0; width: 100%; height: 100%; position: absolute; left: 0; top: 0; z-index: 5; 
    img { position: absolute; left: 0; top: 0; width: 100%; height: 100%; object-fit: cover; border: solid 3px $primary;}
    }
    .play-button { position: absolute; width: 100px; height: 100px; background-color: #fff; display: flex; left: 50%; top: 50%; align-items: center; justify-content: center; z-index: 20;
    color: $primary; font-size: 2.5rem; transform: translate(-50%, -50%); border-radius: 50%; box-shadow: 0px 0px 33px 0px rgba(0, 0, 0, 0.4); transition: all 0.5s linear;
    @media(max-width:767px){ 
        width: 70px; height: 70px;   
        font-size: 1.5rem; 
    }
    }
}
.link-cover { display: block; position: absolute; left: 0; top: 0; width: 100%; height: 100%; z-index: 35;}
&:hover {
    .play-button  { color: $secondary; background-color: $primary;
    i {color: $secondary; }
    }

}
}

.section-singlevideo {
    padding: 30px 0;
    
    .video-box-main{
@media(max-width:767px){
    padding-bottom: 0px;
    margin-top: 15px;
}
        .responsive-frame {
            figure  { 
background-color: transparent;
                img { border: 0;}
            }
        }
    }

}