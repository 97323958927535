.section-hero {
    position: relative;
    padding-bottom: 80px;
    @media(max-width:1199px){ 
        padding-bottom: 60px;  
    }
    @media(max-width:767px){ 
        padding-bottom: 20px;   
    }
    .hero-shap-bottom {
        display: block;
        position: absolute;
        left: 0;
        bottom: 24px;
        width: 100%;
        height: 120px;
        background: url(../images/hero-shap-bottom.png)no-repeat center top;
        z-index: 15;

        @media(max-width:1199px){
            background: url(../images/hero-shap-bottom_1024.png)no-repeat center top;   
            height: 94px;
            bottom: 0px;
        }
        
        @media(max-width:767px){
           
            bottom: auto;
            top: 155px;
        }
        @media(max-width:499px){ 
            background: url(../images/hero-shap-bottom_mobile.png)no-repeat center top;   
            height: 33px;
            top: 163px;

        }
    }
}

.section-hero-slick {
    position: relative;
}

.hero-slick-item {
    float: left;
    position: relative;

    .hero-slick-main-media {
        width: 100%;
        height: auto;

        img {
            width: 100%;
            height: auto;
            object-fit: cover;

            @media(max-width:1366px){
                min-height: 500px;
            }
        }
    }

    .hero-slick-caption {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 25;
        display: flex;
        align-items: center;
    }

    .hero-flex-align {
        display: flex;
        justify-content: flex-end;

        .hero-cp-intro-text-box {
            width: 406px;
        }
    }
}

.hero-cp-intro-text-box {
    .hero-title-text {
        font-size: $px40;
        color: #45484b;
        font-weight: 300;
        padding-bottom: 15px;
        line-height: 1.2;
        @media(max-width:1366px){ 
            font-size: $px36;

        }
        @media(max-width:1199px){ 
            font-size: $px34;

        }
        @media(max-width:767px){ 
            font-size: $px30;

        }
        strong {
            font-weight: 500;
        }
    }

    .hero-intro-text {
        padding-bottom: 15px;
        font-size: $px30;
        color: #45484b;
        font-weight: 300;
        line-height: 1.2;
        @media(max-width:1366px){ 
            font-size: $px24;

        }
        @media(max-width:1199px){ 
            font-size: $px20;

        }
        @media(max-width:767px){ 
            font-size: $px20;

        }
    }

    .hero-readmore-btn {
        .btn {
            color: #fff;
            font-size: $px15;
            text-transform: uppercase;

            span {
                margin-left: 5px;
                position: relative;
                display: inline-block;
                top: -2px;
            }

            &:hover {
                color: $secondary;
            }
        }

    }
}

ul.slick-dots {
    position: absolute;
    left: 0;
    z-index: 55;
    bottom: 0;
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    margin: 0;
    padding: 0;
    justify-content: center;

    li {
        padding: 0 5px;
        text-align: center;
        font-size: 0;
        line-height: 0;

        button {
            margin: 0;
            padding: 0;
            border: 0;
            appearance: none;
            width: 12px;
            font-size: 0;
            line-height: 0;
            height: 12px;
            background-color: $primary;
            border-radius: 50%;
            display: inline-block;
            transition: all 0.4s;

            &:focus {
                outline: none;
            }

        }

        &.slick-active {
            button {
                background-color: #45484b;
            }
        }
    }
}

.mod-customhero {
    position: relative;

    figure {
        width: 100%;
        height: auto;
        margin: 0;

        img {
            width: 100%;
            height: auto;
            object-fit: cover;
            @media(max-width:1365px){ 
                height: 275px;
    
            }
            @media(max-width:767px){ 
                height: 190px;
    
            }
        }
    }

    .customhero-caption {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 15;
        display: flex;
        align-items: center;
        @media(max-width:767px){  
            position: relative;
            height: auto;
            padding-top: 15px;
        }
    }

    .hero-custom-rootheading {
        position: relative;
        font-size: $px35;
        font-weight: 300;
        color: $secondary;
        line-height: 1;
        @media(max-width:1365px){  
            font-size: $px30;   
        }
        &:before {
            content: "";
            height: 100%;
            width: 50%;
            position: absolute;
            left: -50%;
            top: 0;
            background-color: $primary;
        }

        span {
            display: inline-flex;
            height: 56px;
            align-items: center;
            padding-right: 25px;
            position: relative;
            background-color: $primary;

            &:after {
                content: "";
                height: 100%;
                width: 60px;
                background-color: $primary;
                position: absolute;
                right: -30px;
                top: 0;
                transform: skewX(-45deg);
                z-index: -1;
            }

        }
    }

    .hero-custom-subheading,
    h2.hero-custom-subheading {
        font-size: $px50;
        font-weight: 300;
        color: $primary;
        line-height: 1.3;
        margin-top: 10px;
        padding-left: 15px;
        position: relative;
        @media(max-width:1365px){  
            font-size: $px40;   
        }
        @media(max-width:767px){  
            font-size: $px36;   
        }
        @media(max-width:374px){  
            font-size: $px30;   
        }
        &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 7.5px 0 7.5px 10px;
            border-color: transparent transparent transparent $primary;
        }
    }
}

@media(max-width:767px){
    .view-featured,.view-hero-swiper {
        .hero-slick-item .hero-slick-main-media img {
            min-height: 332px;
        }
        .section-hero .hero-shap-bottom { top: auto; bottom: -15px;}
    }
}
@media (max-width: 499px){
    .view-featured,.view-hero-swiper { 
        .section-hero .hero-shap-bottom { top: auto; bottom: 15px;}  
    }  
}