.side-bar-right { border-left: solid 2px $primary; height: 100%;
@media(max-width:767px){
    padding-top: 20px;
        margin-top: 30px;
        border-left: 0;
        border-top: solid 1px $primary; 

}

}
.side-bar-left { padding-bottom: 30px;}
.moduletable_contactinfo { padding-left: 60px;
    @media(max-width:990px){
        padding-left: 15px;   
    }

    @media(max-width:767px){
        padding-left: 0px;
      
    }

    h3 {  font-weight: 300; font-size: $px30; color: $secondary;}
    ul { margin: 0; padding: 0; list-style-type: none;  width: 100%;
    li { padding-top: 40px; 
        display: flex; flex-wrap: wrap;
        figure { margin: 0; text-align: center; flex: 0 0 34px; width: 34px; 
        img { display: inline-block; max-width: 100%; height: auto;}
        }
        .address-box { flex: 0 0 calc(100% - 34px); width: calc(100% - 34px); padding-left: 10px; border-left: solid 2px $secondary;
            @media(max-width:990px){ 
                border-left: solid 1px $secondary;    
            }
        p { margin: 0;  color: $secondary; font-size: $px18; color:  $secondary;
        a {color: $secondary; }
        }
        }
    }
    }
}

.moduletable_leftmod {
    h4 { font-weight: 400; font-size: $px26; color: #2d3032;}
    h3 { font-weight: 400; font-size: $px28; color: #2d3032;}
    h2 { font-weight: 400; font-size: $px30; color: #2d3032;}
    a { color: $secondary;}
    ul { padding: 0; list-style-type: none; margin: 1rem 0;
    li { padding: 5px 0;}
a { color: $secondary; line-height: 1.8;}

    }
    img { max-width: 100%; height: auto;}
    
}
.side-bar-left {

p {
img { margin-top: 20px;}

}
}